import React, { useContext, useEffect, useState } from "react";
import Layout from "../../Layout/Layout";
import PointsDashboard from "../../views/points/PointsDashboard";
import { getOfferProvider } from "../../services/OfferProvidersServices";
import { errorPopup, errorToast } from "../../components/Notifications/Notifications";
import { UserContext } from "../../store/UserContext";
import LanguageContext from "../../store/LanguageContext";
import Dashboard from "../../views/Dashboard";

export const DashboardPage = () => {
  const [offerProvider, setOfferProvider] = useState();
  const { setState } = useContext(UserContext);
  const strings = useContext(LanguageContext);

  useEffect(() => {
    const getOfferProviderData = async () => {
      try {
        const result = await getOfferProvider();
        setOfferProvider(result);
      } catch (error) {
        if (error.code === 401) {
          setState({ authenticated: false, user: null });
          errorToast({
            title: strings.sessionExpired,
          });
          return;
        }
        errorPopup(
          {
            title: strings.getOffersError,
            text: strings.checkInternet,
            showConfirmButton: true,
            timer: 5000,
            confirmButtonText: strings.tryAgainButton,
          },
          getOfferProviderData,
        );
      }
    };
    getOfferProviderData();
  }, []);

  return (
    <Layout isPointUI={offerProvider?.use_point_system_ui_for_tablets}>
      {offerProvider === undefined ? null : offerProvider.use_point_system_ui_for_tablets ? (
        <PointsDashboard offerProvider={offerProvider} />
      ) : (
        <Dashboard />
      )}
    </Layout>
  );
};
